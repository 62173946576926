import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowStirlingLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowStirlingLogo: React.FC<PerthNowStirlingLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 65"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now - Stirling'}</title>
            <path
                d="M9.55175 56.7741C17.027 56.7741 20.9723 53.347 20.9723 47.0853C20.9723 41.1197 18.1483 39.0466 13.0817 38.0735L10.9222 37.6504C8.38892 37.1427 7.55834 36.5504 7.55834 34.9849C7.55834 33.2926 8.88728 32.4887 11.4621 32.4887C14.7429 32.4887 17.2347 33.1656 19.2281 33.8849V27.9616C17.5669 27.1578 15.2828 26.4385 10.7146 26.4385C3.40541 26.4385 0 30.0771 0 35.6196C0 41.7544 2.82399 43.8275 7.39222 44.716L9.55175 45.1391C12.3342 45.6891 13.0817 46.2391 13.0817 47.9315C13.0817 49.7085 11.8359 50.6816 8.59657 50.6816C5.27422 50.6816 2.61635 49.9623 0.0830587 49.0315V55.0394C2.15953 56.1394 5.5234 56.7741 9.55175 56.7741Z"
                fill="black"
            />
            <path
                d="M31.645 56.6048C33.9707 56.6048 35.6318 56.1394 36.504 55.674V50.8931C35.9225 51.02 35.092 51.147 34.3444 51.147C32.5587 51.147 31.8942 50.6393 31.8942 49.0738V38.9197H36.3794V33.758H31.8942V28.427H24.585V33.758H21.8441V38.9197H24.585V50.1316C24.585 55.0817 27.2014 56.6048 31.645 56.6048Z"
                fill="black"
            />
            <path
                d="M39.0285 56.2664H46.2961V33.758H39.0285V56.2664ZM38.987 31.2194H46.3376V25.55H38.987V31.2194Z"
                fill="black"
            />
            <path
                d="M49.6869 56.2664H56.9546V41.0351C58.2004 40.4005 59.8616 40.0197 61.8135 40.0197C62.4364 40.0197 63.2255 40.1466 63.6823 40.2312V33.7157C63.4331 33.5887 62.9763 33.5041 62.3949 33.5041C60.0693 33.5041 58.0758 34.7311 56.913 36.4657H56.7054V33.758H49.6869V56.2664Z"
                fill="black"
            />
            <path
                d="M65.9074 56.2664H73.175V25H65.9074V56.2664Z"
                fill="black"
            />
            <path
                d="M76.6904 56.2664H83.958V33.758H76.6904V56.2664ZM76.6489 31.2194H83.9995V25.55H76.6489V31.2194Z"
                fill="black"
            />
            <path
                d="M87.3488 56.2664H94.6165V39.8928C95.5301 39.512 96.9006 39.1312 98.0634 39.1312C100.098 39.1312 100.846 39.7658 100.846 41.5428V56.2664H108.114V39.512C108.114 35.0695 105.705 33.3349 101.593 33.3349C98.8109 33.3349 96.4853 34.1811 94.5749 35.7465H94.3673V33.758H87.3488V56.2664Z"
                fill="black"
            />
            <path
                d="M119.998 64.7282C128.179 64.7282 132 61.3857 132 56.4779C132 51.9931 129.259 50.1316 124.566 50.1316H118.295C117.049 50.1316 116.593 49.7508 116.593 48.8623C116.593 48.4392 116.759 48.0584 117.008 47.7199C118.005 47.9315 119.043 48.0161 120.206 48.0161C125.937 48.0161 129.633 45.6468 129.633 40.8659V40.5697C129.633 39.512 129.384 38.6658 128.885 37.9889H131.709V33.7157H124.276C123.071 33.4618 121.701 33.3349 120.247 33.3349C114.35 33.3349 110.654 35.8734 110.654 40.739V41.0351C110.654 43.616 111.734 45.4353 113.603 46.5776C111.983 47.8046 110.862 49.37 110.862 51.2316C110.862 52.9663 111.9 54.0663 113.27 54.6586V54.8702C110.945 55.6317 109.159 57.0279 109.159 59.3549C109.159 62.5704 112.44 64.7282 119.998 64.7282ZM119.956 60.2011C116.676 60.2011 115.804 59.3972 115.804 58.0856C115.804 57.0279 116.426 56.4779 117.299 56.1394H121.991C124.234 56.1394 124.815 56.4356 124.815 57.7895C124.815 59.1857 123.694 60.2011 119.956 60.2011ZM120.247 43.8698C118.461 43.8698 117.548 43.1083 117.548 40.9082V40.4851C117.548 38.3696 118.461 37.5658 120.206 37.5658C121.908 37.5658 122.864 38.1581 122.864 40.5274V40.9505C122.864 43.1083 121.95 43.8698 120.247 43.8698Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowStirlingLogo
